import axios from "axios";
import Store from '@/store'

export default {
  getToken () {
    return localStorage.getItem('jwt_token')
  },

  setToken (token) {
    localStorage.setItem('jwt_token', token)
  },

  deleteToken () {
    localStorage.removeItem('jwt_token')
  },

  processHeaders (header) {
    let token = this.getToken()
    if (token) {
      return header === true ? {Authorization: `Bearer ${token}`} : header
    } else {
      return header === true ? {} : header
    }
  },

  get (url, params = {}, notification = false, auth = true, callback = undefined) {
    return this.submit('get', url, params, notification, auth, callback)
  },

  post (url, data = {}, notification = false, auth = true, callback = undefined) {
    return this.submit('post', url, data, notification, auth, callback)
  },

  put (url, data = {}, notification = false, auth = true, callback = undefined) {
    return this.submit('put', url, data, notification, auth, callback)
  },

  delete (url, data = {}, notification = false, auth = true, callback = undefined) {
    return this.submit('delete', url, data, notification, auth, callback)
  },

  submitOnce (method, url, params = {}, auth = undefined, callback = undefined) {
    let header = this.processHeaders(auth)
    if (params instanceof FormData) {
      header['Content-Type'] = 'multipart/form-data'
    }
    let obj = {
      method: method,
      url: url,
      headers: header,
      onUploadProgress: (progressEvent) => {
        if (callback) {
          let loaded = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
          callback(loaded)
        }
      }
    }
    if (String(method).toLowerCase() === 'get') {
      obj.params = params
    } else {
      obj.data = params
    }
    return axios(obj)
  },

  submit (method, url, params = {}, notification = false, auth = true, callback = undefined) {
    if (notification) Store.commit('s', ['progress', true])
    return new Promise((resolve, reject) => {
      this.submitOnce(method, url, params, auth, callback)
        .then(response => {
          this._handleSuccess(response, notification)
          resolve(response)
        })
        .catch(error => {
          if (this._shouldRefreshToken(error)) {
            this.submitOnce('get', '/api/auth/refresh', {}, true)
              .then(response => {
                this.setToken(response.data.access_token)
                this.submitOnce(method, url, params, auth, callback)
                  .then(response => {
                    this._handleSuccess(response, notification)
                    resolve(response)
                  })
                  .catch(error => {
                    this._handleError(error, notification)
                    reject(error)
                  })
              })
              .catch(error => {
                this._handleError(error, notification)
                reject(error)
              })
          } else {
            this._handleError(error, notification)
            reject(error)
          }

        })
    })

  },

  _shouldRefreshToken (error) {
    return error.response && error.response.status === 401
      && error.response.data.code === 'token_expire'
  },

  _handleError (error, notify) {
    Store.commit('s', ['progress', false])
    if (!error.response ||!error.response.status) {
      Store.commit('message/SHOW_ERROR', 'Server error')
    } else {
      let code = error.response.status
      if (code === 401 && error.response.data.message === 'Token not provided') {

      }
      else if (code === 403 && error.response.data.code === 'validation') {
        let data = error.response.data.data
        let key = Object.keys(data)[0]
        Store.commit('message/SHOW_ERROR', data[key][0])
      }
      else if (code === 422 && error.response.data.errors) {
        let errors = error.response.data.errors
        let message = ""
        for (let key in errors) {
          let error = errors[key]
          error.forEach(e => {
            message += e + " "
          })
        }
        Store.commit('message/SHOW_ERROR', message)
      }
      else if (code === 401) {
        let errors = error.response.data.errors
        let message = ""
        for (let key in errors) {
          let error = errors[key]
          error.forEach(e => {
            message += e + " "
          })
        }
        // alert(error.response.data.message)
        Store.commit('message/SHOW_ERROR', error.response.data.message)
      }
      else {
        Store.commit('message/SHOW_ERROR', error.response.data.message)
      }
    }
  },

  _handleSuccess (success, notify) {
    Store.commit('s', ['progress', false])
    if (notify) {
      Store.commit('message/SHOW_SUCCESS', success.data.message || 'Success')
    }
  }
}
