<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div ref="mapSearch" style=" width: 100%; height: 100%;" @mouseleave="deSelect"/>
    <!--    <DrawTool style="position: absolute; top: 60px; right: 10px" @changeMode="changeMode"/>-->
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import bbox from '@turf/bbox'
import sleep from "../../../../ultis/sleep";

let map
let draw
export default {
  components: {DrawTool},
  name: "BaseMap",
  data() {
    return {
      layers: [],
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      listImage: [],
      addedLayer: undefined
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: this.$refs.mapSearch, // container id
      style: 'https://tiles.eofactory.ai/styles/basic/style.json',
      center: [103.816945, 1.355359], // starting position
      zoom: 9.5, // starting zoom,
      attributionControl: false
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })

    // map.addControl(new RulerControl(), 'bottom-right')
    this.initDrawControlShortcuts()
    map.addControl(draw)
    map.on('style.load', async () => {
      await sleep(200)
      await this.reSize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
    })
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
  },

  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

    this.destroyDrawControlShortcuts()
  },
  props: {
    index: {type: Number, default: 1}
  },
  methods: {
    async reSize() {
      let mapCanvas = document.getElementsByClassName("mapboxgl-canvas")[this.index];
      if (mapCanvas) {
        mapCanvas.style.height = "100%";
        mapCanvas.style.width = "100%";
    }
      await sleep(200)
      map.resize();
    },
    deSelect() {
      draw.changeMode('simple_select')
    },
    initDrawControlShortcuts() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (draw.getMode() !== 'draw_polygon') {
              draw.changeMode('draw_polygon')
            }
            break
          case '4':
          case 'w':
            if (draw.getMode() !== 'draw_rectangle') {
              draw.changeMode('draw_rectangle')
            }
            break
          case '5':
          case 'e':
            if (draw.getMode() !== 'draw_assisted_rectangle') {
              draw.changeMode('draw_assisted_rectangle')
            }
            break
          case '6':
          case 'r':
            if (draw.getMode() !== 'drag_circle') {
              draw.changeMode('drag_circle')
            }
            break
          case 'Delete':
          case 'Backspace':
            if (draw.getSelected()) {
              draw.trash()
            }
            break
          case 'Asc':
            draw.changeMode('simple_select')
            break
          default:
            draw.changeMode('simple_select')
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    changeMode(mode) {
      switch (mode) {
        case 'polygon':
          if (draw.getMode() !== 'draw_polygon') {
            draw.changeMode('draw_polygon')
          }
          break
        case 'rectangle':
          if (draw.getMode() !== 'draw_rectangle') {
            draw.changeMode('draw_rectangle')
          }
          break
        case 'assist-rectangle':
          if (draw.getMode() !== 'draw_assisted_rectangle') {
            draw.changeMode('draw_assisted_rectangle')
          }
          break
        case 'drag-circle':
          if (draw.getMode() !== 'drag_circle') {
            draw.changeMode('drag_circle')
          }
          break
        case 'drag-point':
          if (draw.getMode() !== 'draw_point') {
            draw.changeMode('draw_point')
          }
          break
        case 'delete':
          if (draw.getSelected()) {
            draw.trash()
          }
          break
        default:
          break
      }
    },
    removeLayer() {
      this.layers.forEach(layer => {
        map.removeLayer(layer.id).removeSource(layer.id)
      })
      this.layers = []
    },
    addNDVILayer(image) {
      this.removeLayer()
      map.addSource(image.id, {
        'type': 'raster',
        'tiles': [image.tile_url],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      })
      const layer = {
        id: image.id,
        'type': 'raster',
        'source': image.id,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'raster-fade-duration': 0
        }
      }
      this.layers.push(layer)
      map.addLayer(layer)
      this.submitZoom(image.bbox)
    },
    zoomToBound(currentImage) {
      this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    }
  },
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}


/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
</style>
