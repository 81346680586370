<template>
  <v-card flat style="height: calc(100vh - 130px); background: #f4ecfd; border-radius: 0px">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card-text class="fill-height" style="border-radius: 10px; padding: 0px 36px 16px 36px">
      <div class="mr-6"
           style="border-radius: 10px; width: 100%; height: 100%; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background-color: #fff">
        <div
            style="border-top-right-radius: 10px; border-top-left-radius: 10px; height: 70px; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background: linear-gradient(180deg, #EFEFEF 76.97%, rgba(227, 227, 227, 0.28) 100%)"
            class="px-0 py-1">
          <v-layout class="fill-height">
            <div class="fill-height" style="width: 50%">
              <v-layout class="fill-height px-5" align-center>
                <span style="font-size: 0.8vw; font-weight: 700;">Overview of Changes in </span>
                <div style="width: 300px" class="pl-3">
                  <v-autocomplete
                      flat
                      class="elevation-0 select-input"
                      @change="changeAOI"
                      v-model="aoi"
                      :items="aois"
                      solo
                      dense
                      hide-details
                      item-text="name"
                      return-object/>
                </div>
              </v-layout>
            </div>
            <div class="flex fill-height pl-3">
              <v-layout class="fill-height" align-center justify-end>
                <div style="width: 300px;" class="mx-2">
                  <v-layout class="fill-height" align-center>
                    <div style="width: 150px; font-size: 0.8vw; font-weight: 700;">Compare Month</div>
                    <MonthPicker :max="recordedFor" left label="Current month" v-model="compareDate" @input="getData"/>
                  </v-layout>
                  <!--                  <v-select-->
                  <!--                      solo-inverted-->
                  <!--                      background-color="#f8f8f800"-->
                  <!--                      flat-->
                  <!--                      @change="getData"-->
                  <!--                      dense-->
                  <!--                      hide-details-->
                  <!--                      v-model="compareYear"-->
                  <!--                      :items="allowYears"-->
                  <!--                  ></v-select>-->
                </div>
                <div style="width: 300px;" class="mr-2">
                  <v-layout class="fill-height" align-center>
                    <div style="width: 150px; font-size: 0.8vw; font-weight: 700;">Current Month</div>
                    <MonthPicker left :min="compareDate" label="Current month" v-model="recordedFor" @input="getData"/>
                  </v-layout>
                </div>
<!--                <span class="mr-4" style="color: #893FF2; font-size: 13px; font-weight: 700"> Jan 2021</span>-->
<!--                <v-card style="border-radius: 8px; margin-top: -10px" width="100%" height="32" class="px-2">-->
<!--                  <v-range-slider-->
<!--                      @change="getData"-->
<!--                      class="monthRanger"-->
<!--                      color="#893FF2"-->
<!--                      v-model="month"-->
<!--                      hide-details-->
<!--                      :tick-labels="monthLabels"-->
<!--                      :max="12"-->
<!--                      :min="1"-->
<!--                      step="1"-->
<!--                      ticks="always"-->
<!--                      tick-size="4"-->
<!--                  ></v-range-slider>-->
<!--                </v-card>-->
<!--                <span class="ml-4" style="color: #893FF2; font-size: 13px; font-weight: 700"> Dec 2021</span>-->
              </v-layout>
            </div>
          </v-layout>
        </div>
        <div style="height: calc(100% - 70px);">
          <v-layout class="fill-height pa-3">
            <v-layout class="fill-height pa-3 elevation-2" style="border-radius: 10px;">
              <v-layout style="height: 100%; width: 50%; border-radius: 11px 0 0 11px;"
                        class="overflow-y-hidden elevation-2" column>
                <v-tabs
                    fixed-tabs
                    color="#893FF2"
                    background-color="#F7F3F8"
                    v-model="tab"
                    align-with-title
                >
                  <v-tabs-slider color="#893FF2"></v-tabs-slider>

                  <v-tab class="ml-0">
                    Green Cover
                  </v-tab>
                  <v-tab>
                    Forest Cover
                  </v-tab>
                  <v-tab>
                    Plant Health NDVI
                  </v-tab>
                  <v-tab>
                    Plant Density
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" style="height: 100%">
                  <!--                  Green cover area-->
                  <v-tab-item style="width: 100%; height: 100%">
                    <v-card flat class="d-flex flex-column fill-height" style="width: 100%; background-color: #F7F3F8">
                      <v-card-text class="fill-height overflow-y-auto d-flex flex-column">
                        <div style="flex: none; height: 350px; width: 100%">
                          <div style="width: 100%; height: 150px; flex: none">
                            <v-layout class="fill-height">
                              <div class="px-0" style="flex: 1;">
                                <v-layout column justify-center fill-height>
                                  <v-layout style="height: 8vh" column justify-center>
                                    <span
                                        style="font-size: 36px; font-weight: 600; color: #000000;">Green Cover</span>
                                  </v-layout>
                                  <span style="font-size: 16px; color: #000000; line-height: 50px; font-weight: 500">Data for the month of
                                    <span style="color: #893FF2">{{ convertMonthText(recordedFor.split("-")[1]) }}/{{ recordedFor.split("-")[0] }}</span>
                                  </span>
                                </v-layout>
                              </div>
                              <div class="px-0 pb-3" style="flex: 1;">
                                <v-layout column align-end justify-end class="fill-height">
                                  <v-layout class="fill-height" align-end>
                                    <span style="font-size: 16px; color: #000000; line-height: 30px; font-weight: 500">Data for the month of </span>
                                    <v-spacer/>
                                    <span style="font-size: 16px; color: #893FF2; line-height: 30px; font-weight: 500">&nbsp; {{ convertMonthText(compareDate.split("-")[1]) }}/{{ this.recordedFor.split("-")[0] }}</span>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                          <div style="width: 100%; min-height: 190px; flex: none; ">
                            <v-layout style="height: 190px">
                              <div class="px-5 py-5 mr-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <div
                                      style="margin-top: 20px; height: 105px; width: 100%; border-top: 1px #893FF2 solid; border-right: 1px #893FF2 solid; border-bottom: 1px #893FF2 solid; position: relative">
                                    <div class="pr-3"
                                         style="min-width: 200px; height: 80px; font-weight: 600; font-size: 2.7vw; color: #893FF2; position: absolute; top: -30px; left: 0; background-color: white">
                                      <v-layout class="fill-height" align-center>
                                        {{
                                          (data && data.green_cover_area) ? transformNumber((data.green_cover_area).toFixed(2)) : 'N/A'
                                        }}
                                        <sup style="font-weight: 400; font-size: 1.5vw">sqkm</sup>
                                      </v-layout>
                                    </div>
                                    <div
                                        style="height: 80px; position: absolute; bottom: -27px; left: 0; background-color: white"
                                        class="pr-3 d-flex align-center">
                                      <v-layout column>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #9C7A00">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 0.9vw">- </span> Non Green Area: </span>
                                           {{
                                            (data && data.non_green_area) ? transformNumber((data.non_green_area).toFixed(2)) : 0
                                          }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bolder; color: #1F7BB0">
                                        <span class="pl-2"><span
                                            style="color: #893FF2; font-weight: bold; font-size: 0.8vw">- </span> Water Area: </span>
                                          <b>
                                           {{
                                              (data && data.water_area) ? transformNumber((data.water_area).toFixed(2)) : 0
                                            }} sqkm
                                          </b>
                                        </span>
                                      </v-layout>
                                    </div>
                                  </div>
                                </v-layout>
                              </div>
                              <div class="px-5 py-5 ml-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <div
                                      style="margin-top: 20px; height: 105px; width: 100%; border-top: 1px #893FF2 solid; border-right: 1px #893FF2 solid; border-bottom: 1px #893FF2 solid; position: relative">
                                    <div class="pr-3"
                                         style="min-width: 200px; height: 80px; font-weight: 600; font-size: 2.7vw; color: #893FF2; position: absolute; top: -30px; left: 0; background-color: white">
                                      <v-layout class="fill-height" align-center>
                                        {{
                                          (compareData && compareData.green_cover_area) ? transformNumber(compareData.green_cover_area.toFixed(2)) : 'N/A'
                                        }}
                                        <sup style="font-weight: 400; font-size: 1.5vw">sqkm</sup>
                                      </v-layout>
                                    </div>
                                    <div
                                        style="height: 80px; position: absolute; bottom: -27px; left: 0; background-color: white"
                                        class="pr-3 d-flex align-center">
                                      <v-layout column>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #9C7A00">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 1.2vw">- </span> Non Green Area: </span>
                                           {{
                                            (compareData && compareData.non_green_area) ? transformNumber(compareData.non_green_area.toFixed(2)) : 0
                                          }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bolder; color: #1F7BB0">
                                        <span class="pl-2"><span
                                            style="color: #893FF2; font-weight: bold; font-size: 0.9vw">- </span> Water Area: </span>
                                          <b>
                                           {{
                                              (compareData && compareData.water_area) ? transformNumber(compareData.water_area.toFixed(2)) : 0
                                            }} sqkm
                                          </b>
                                        </span>
                                      </v-layout>
                                    </div>
                                  </div>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                        <div style="height: 100%; width: 100%;" class="d-flex py-2">
                          <div
                              style="height: 100%; width: 100%; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 11px; background-color: #FFFFFF"
                              class="d-flex pa-5">
                            <v-layout column class="fill-height" justify-center>
                              <div style="height: 5vh; font-weight: 600; font-size: 3vw; color: #000000" class="mt-5">
                                <v-layout class="fill-height" align-center>
                                  <span style="font-size: 4vw; font-weight: 700; color: #000000">{{
                                      (data && data.area_percent_change) ? Math.abs(parseFloat(data.area_percent_change).toFixed(2)) : ''
                                    }}%</span>
                                  <v-icon v-if="data && parseFloat( data.area_percent_change) < 0" size="70" color="red"
                                          style="margin-bottom: -30px">mdi-menu-down
                                  </v-icon>
                                  <v-icon v-else size="70" color="green" style="margin-top: -30px">mdi-menu-up</v-icon>
                                  <v-layout column>
                                    <span
                                        style="line-height: 3vh; font-size: 1.1vw; text-transform: uppercase; color: #000000">Change in Green Cover Area</span>
                                    <div style="height: 50px;" class="d-flex align-center">
                                      <span style="font-size: 18px; font-weight: bold;"
                                            :style="{'color': (data && data.area_change) > 0 ? 'green' : 'red'}">
                                        {{
                                          (data && data.area_change) ? transformNumber((data.area_change).toFixed()) : 0
                                        }} sqkm
                                        <span style="font-weight: normal; color: black" class="pl-2"> in Green Cover area compared to <span
                                            style="color: #893FF2; font-weight: bold">{{ convertMonthText(recordedFor.split("-")[1]) }}</span> records.</span>
                                      </span>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!--                  Forest cover area-->
                  <v-tab-item style="width: 100%; height: 100%">
                    <v-card flat class="d-flex flex-column fill-height" style="width: 100%; background-color: #F7F3F8">
                      <v-card-text class="fill-height overflow-y-auto d-flex flex-column">
                        <div style="flex: none; height: 350px; width: 100%">
                          <div style="width: 100%; height: 150px; flex: none">
                            <v-layout class="fill-height">
                              <div class="px-0" style="flex: 1;">
                                <v-layout column justify-center fill-height>
                                  <v-layout style="height: 8vh" column justify-center>
                                    <span
                                        style="font-size: 36px; font-weight: 600; color: #000000;">Forest Cover</span>
                                  </v-layout>
                                  <span style="font-size: 16px; color: #000000; line-height: 50px; font-weight: 500">Data for the month of
                                    <span style="color: #893FF2">{{ convertMonthText(recordedFor.split("-")[1]) }}/{{ recordedFor.split("-")[0] }}</span>
                                  </span>
                                </v-layout>
                              </div>
                              <div class="px-0 pb-3" style="flex: 1;">
                                <v-layout column align-end justify-end class="fill-height">
                                  <v-layout class="fill-height" align-end>
                                    <span style="font-size: 16px; color: #000000; line-height: 30px; font-weight: 500">Data for the month of </span>
                                    <v-spacer/>
                                    <span style="font-size: 16px; color: #893FF2; line-height: 30px; font-weight: 500">&nbsp; {{ convertMonthText(compareDate.split("-")[1]) }}/{{ compareDate.split("-")[0] }}</span>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                          <div style="width: 100%; min-height: 190px; flex: none; ">
                            <v-layout style="height: 190px">
                              <div class="px-5 py-5 mr-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <div
                                      style="margin-top: 20px; height: 105px; width: 100%; border-top: 1px #893FF2 solid; border-right: 1px #893FF2 solid; border-bottom: 1px #893FF2 solid; position: relative">
                                    <div class="pr-3"
                                         style="min-width: 200px; height: 80px; font-weight: 600; font-size: 2.7vw; color: #893FF2; position: absolute; top: -30px; left: 0; background-color: white">
                                      <v-layout class="fill-height" align-center>
                                        {{
                                          (dataForest && dataForest.forest_cover_area) ? transformNumber((dataForest.forest_cover_area).toFixed(2)) : 'N/A'
                                        }}
                                        <sup style="font-weight: 400; font-size: 1.5vw">sqkm</sup>
                                      </v-layout>
                                    </div>
                                    <div
                                        style="height: 80px; position: absolute; bottom: -27px; left: 0; background-color: white"
                                        class="pr-3 d-flex align-center">
                                      <v-layout column>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #2fc500">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 1.2vw">- </span> Non Forest Green Area: </span>
                                           {{ (dataForest && dataForest.non_forest_green_area) ? transformNumber(dataForest.non_forest_green_area.toFixed(2)) : 0 }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #9C7A00">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 0.9vw">- </span> Non Green Area: </span>
                                           {{
                                            (data && data.non_green_area) ? transformNumber((data.non_green_area).toFixed(2)) : 0
                                          }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bolder; color: #1F7BB0">
                                        <span class="pl-2"><span
                                            style="color: #893FF2; font-weight: bold; font-size: 0.8vw">- </span> Water Area: </span>
                                          <b>
                                           {{
                                              (data && data.water_area) ? transformNumber((data.water_area).toFixed(2)) : 0
                                            }} sqkm
                                          </b>
                                        </span>
                                      </v-layout>
                                    </div>
                                  </div>
                                </v-layout>
                              </div>
                              <div class="px-5 py-5 ml-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <div
                                      style="margin-top: 20px; height: 105px; width: 100%; border-top: 1px #893FF2 solid; border-right: 1px #893FF2 solid; border-bottom: 1px #893FF2 solid; position: relative">
                                    <div class="pr-3"
                                         style="min-width: 200px; height: 80px; font-weight: 600; font-size: 2.7vw; color: #893FF2; position: absolute; top: -30px; left: 0; background-color: white">
                                      <v-layout class="fill-height" align-center>
                                        {{
                                          (compareForestData && compareForestData.forest_cover_area) ? transformNumber(compareForestData.forest_cover_area.toFixed(2)) : 'N/A'
                                        }}
                                        <sup style="font-weight: 400; font-size: 1.5vw">sqkm</sup>
                                      </v-layout>
                                    </div>
                                    <div
                                        style="height: 80px; position: absolute; bottom: -27px; left: 0; background-color: white"
                                        class="pr-3 d-flex align-center">
                                      <v-layout column>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #2fc500">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 1.2vw">- </span> Non Forest Green Area: </span>
                                           {{
                                            (compareForestData && compareForestData.non_forest_green_area) ? transformNumber(compareForestData.non_forest_green_area.toFixed(2)) : 0
                                          }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bold; color: #9C7A00">
                                          <span class="pl-2"><span
                                              style="color: #893FF2; font-weight: bold; font-size: 1.2vw">- </span> Non Green Area: </span>
                                           {{
                                            (compareData && compareData.non_green_area) ? transformNumber(compareData.non_green_area.toFixed(2)) : 0
                                          }} sqkm
                                        </span>
                                        <span style="font-size: 0.9vw; font-weight: bolder; color: #1F7BB0">
                                        <span class="pl-2"><span
                                            style="color: #893FF2; font-weight: bold; font-size: 1.2vw">- </span> Water Area: </span>
                                          <b>
                                           {{
                                              (compareData && compareData.water_area) ? transformNumber(compareData.water_area.toFixed(2)) : 0
                                            }} sqkm
                                          </b>
                                        </span>
                                      </v-layout>
                                    </div>
                                  </div>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                        <div style="height: 100%; width: 100%;" class="d-flex py-2">
                          <div
                              style="height: 100%; width: 100%; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 11px; background-color: #FFFFFF"
                              class="d-flex pa-5">
                            <v-layout column class="fill-height" justify-center>
                              <div style="height: 5vh; font-weight: 600; font-size: 3vw; color: #000000" class="mt-5">
                                <v-layout class="fill-height" align-center>
                                  <span style="font-size: 4vw; font-weight: 700; color: #000000">{{
                                      (dataForest && dataForest.area_percent_change) ? Math.abs(parseFloat(dataForest.area_percent_change)).toFixed(2) : ''
                                    }}%</span>
                                  <v-icon v-if="dataForest && parseFloat( dataForest.area_percent_change) < 0" size="70"
                                          color="red"
                                          style="margin-bottom: -30px">mdi-menu-down
                                  </v-icon>
                                  <v-icon v-else size="70" color="green" style="margin-top: -30px">mdi-menu-up</v-icon>
                                  <v-layout column>
                                    <span
                                        style="line-height: 3vh; font-size: 1.1vw; text-transform: uppercase; color: #000000">Change in Forest Cover Area</span>
                                    <div style="height: 50px;" class="d-flex align-center">
                                      <span style="font-size: 18px; font-weight: bold;"
                                            :style="{'color': (dataForest && dataForest.area_change) > 0 ? 'green' : 'red'}">
                                        {{
                                          (dataForest && dataForest.area_change) ? transformNumber((dataForest.area_change).toFixed(2)) : 0
                                        }} sqkm
                                        <span style="font-weight: normal; color: black" class="pl-2"> in Forest Cover area compared to <span
                                            style="color: #893FF2; font-weight: bold">{{ convertMonthText(recordedFor.split("-")[1]) }}</span> records.</span>
                                      </span>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!--                  Plant health-->
                  <v-tab-item style="width: 100%; height: 100%">
                    <v-card flat class="d-flex flex-column fill-height" style="width: 100%; background-color: #F7F3F8">
                      <v-card-text class="fill-height overflow-y-auto d-flex flex-column">
                        <div style="flex: none; height: 380px; width: 100%">
                          <div style="width: 100%; height: 150px; flex: none">
                            <v-layout class="fill-height">
                              <div class="px-0" style="flex: 1;">
                                <v-layout column justify-center fill-height>
                                  <v-layout style="height: 8vh" column justify-center>
                                    <span
                                        style="font-size: 36px; font-weight: 600; color: #000000;">Plant Health NDVI</span>
                                  </v-layout>
                                  <span style="font-size: 16px; color: #000000; line-height: 50px; font-weight: 500">Data for the month of
                                    <span style="color: #893FF2">{{ convertMonthText(recordedFor.split("-")[1]) }}/{{ recordedFor.split("-")[0] }}</span>
                                  </span>
                                </v-layout>
                              </div>
                              <div class="px-0 pb-3" style="flex: 1;">
                                <v-layout column align-end justify-end class="fill-height">
                                  <v-layout class="fill-height" align-end>
                                    <span style="font-size: 16px; color: #000000; line-height: 30px; font-weight: 500">Data for the month of </span>
                                    <v-spacer/>
                                    <span style="font-size: 16px; color: #893FF2; line-height: 30px; font-weight: 500">&nbsp; {{ convertMonthText(compareDate.split("-")[1]) }}/{{ compareDate.split("-")[0] }}</span>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                          <div style="width: 100%; min-height: 230px; flex: none; ">
                            <v-layout style="height: 230px">
                              <div class="px-5 py-5 mr-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <v-layout column class="fill-height pt-4">
                                    <div style="flex: none; height: 5vh">
                                      <v-layout>
                                        <span class="pb-3"
                                              style="color: #893FF2; font-size: 1.7vw; font-weight: 800; font-style: italic">Plant Health NDVI</span>
                                        <v-spacer/>
                                        <b style="color: #893FF2">sqkm</b>
                                      </v-layout>
                                    </div>
                                    <div style="width: 100%; height: 2.5vh"
                                         v-for="(data, index) of dataTablePlantHealth" :key="index">
                                      <v-layout align-center>
                                        <div style="width: 10vw">
                                          <span :style="{'color': data.color}"
                                                style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                              data.label
                                            }}</span>
                                        </div>
                                        <v-layout style="max-width: 15vw">
                                          <div :style="{'background-color': data.color}"
                                               style="height: 10px; width: 100%;"/>
                                        </v-layout>
                                        <div class="pl-3" style="width: 90px; text-align: end">
                                          <span style=" width: 90px; color: green">{{
                                              transformNumber((data.value).toFixed(2))
                                            }}</span>
                                        </div>
                                      </v-layout>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                              <div class="px-5 py-5 ml-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <v-layout column class="fill-height pt-4">
                                    <div style="flex: none; height: 5vh">
                                      <v-layout>
                                        <span class="pb-3"
                                              style="color: #893FF2; font-size: 1.7vw; font-weight: 800; font-style: italic">Plant Health NDVI</span>
                                        <v-spacer/>
                                        <b style="color: #893FF2">sqkm</b>
                                      </v-layout>
                                    </div>
                                    <div style="width: 100%; height: 2.5vh" v-for="(data, index) of comparePlantHealth"
                                         :key="index">
                                      <v-layout align-center>
                                        <div style="width: 10vw">
                                          <span :style="{'color': data.color}"
                                                style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                              data.label
                                            }}
                                          </span>
                                        </div>
                                        <v-layout style="max-width: 15vw">
                                          <div :style="{'background-color': data.color}"
                                               style="height: 10px; width: 100%;"/>
                                        </v-layout>
                                        <div class="pl-3" style="width: 90px; text-align: end">
                                          <span style=" width: 90px; color: green">{{
                                              transformNumber((data.value).toFixed(2))
                                            }}</span>
                                        </div>
                                      </v-layout>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                        <div style="height: 100%; width: 100%;" class="d-flex py-2">
                          <div
                              style="height: 100%; width: 100%; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 11px; background-color: #FFFFFF"
                              class="d-flex pa-5">
                            <v-layout column class="fill-height pt-3">
                              <div style="flex: none; height: 5vh">
                                <v-layout>
                                  <span class="pb-3"
                                        style="color: #000000; font-size: 1.7vw; font-weight: bold; font-style: italic">% Change in Plant Health NDVI</span>
                                  <!--                                    <span class="pb-3" style="color: #000000; font-size: 1.7vw; font-weight: 700; font-style: italic">Compared to {{compareMonth.name}}</span>-->
                                </v-layout>
                              </div>
                              <div style="width: 100%; height: 2.5vh" v-for="(data, index) of dataTablePlantHealth"
                                   :key="index">
                                <v-layout align-center justify-start>
                                  <div style="width: 200px; white-space: nowrap">
                                    <div style="width: 100px; text-align: left">
                                      <span :style="{'color': data.color}"
                                            style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                          data.label
                                        }}</span>
                                    </div>
                                  </div>
                                  <v-layout style="max-width: 15vw" justify-center class="pl-5">
                                    <div :style="{'background-color': data.color}"
                                         style="height: 10px; width: 100%;"></div>
                                  </v-layout>
                                  <div class="pl-3" style="min-width: 70px; text-align: end">
                                       <span :style="{'color': data.change_percent >= 0 ? 'green' : 'red'}"
                                             style="font-size: 0.6vw; color: green; font-weight: bold; width: 200px">
                                         {{ data.value.toFixed(2) == 0.00 ? 'N/A' : data.change_percent.toFixed(2) }}%
                                      </span>
                                    <!--                                      <span v-if="data.change_percent >= 0" class="pl-4" style="font-size: 0.75vw; min-width: 100px; color: green"> INCREASE</span>-->
                                    <!--                                      <span v-else class="pl-4" style="font-size: 0.75vw; min-width: 100px; color: red"> DECREASE</span>-->
                                  </div>
                                  <v-icon v-if="data.change_percent >= 0" color="green">mdi-menu-up</v-icon>
                                  <v-icon v-else color="red">mdi-menu-down</v-icon>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!--                  Green Density-->
                  <v-tab-item style="width: 100%; height: 100%">
                    <v-card flat class="d-flex flex-column fill-height" style="width: 100%; background-color: #F7F3F8">
                      <v-card-text class="fill-height overflow-y-auto d-flex flex-column">
                        <div style="flex: none; height: 380px; width: 100%">
                          <div style="width: 100%; height: 150px; flex: none">
                            <v-layout class="fill-height">
                              <div class="px-0" style="flex: 1;">
                                <v-layout column justify-center fill-height>
                                  <v-layout style="height: 8vh" column justify-center>
                                    <span
                                        style="font-size: 36px; font-weight: 600; color: #000000;">Green Density</span>
                                  </v-layout>
                                  <span style="font-size: 16px; color: #000000; line-height: 50px; font-weight: 500">Data for the month of
                                    <span style="color: #893FF2">{{ convertMonthText(recordedFor.split("-")[1]) }}/{{ recordedFor.split("-")[0] }}</span>
                                  </span>
                                </v-layout>
                              </div>
                              <div class="px-0 pb-3" style="flex: 1;">
                                <v-layout column align-end justify-end class="fill-height">
                                  <v-layout class="fill-height" align-end>
                                    <span style="font-size: 16px; color: #000000; line-height: 30px; font-weight: 500">Data for the month of </span>
                                    <v-spacer/>
                                    <span style="font-size: 16px; color: #893FF2; line-height: 30px; font-weight: 500">&nbsp; {{ convertMonthText(compareDate.split("-")[1]) }}/{{ compareDate.split("-")[0] }}</span>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                          <div style="width: 100%; min-height: 230px; flex: none; ">
                            <v-layout style="height: 230px">
                              <div class="px-5 py-5 mr-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <v-layout column class="fill-height pt-4">
                                    <div style="flex: none; height: 5vh">
                                      <v-layout>
                                        <span class="pb-3"
                                              style="color: #893FF2; font-size: 1.7vw; font-weight: 800; font-style: italic">Green Density</span>
                                        <v-spacer/>
                                        <b style="color: #893FF2">sqkm</b>
                                      </v-layout>
                                    </div>
                                    <div style="width: 100%; height: 2.5vh"
                                         v-for="(data, index) of dataTableGreenDensity" :key="index">
                                      <v-layout align-center>
                                        <div style="width: 10vw">
                                          <span :style="{'color': data.color}"
                                                style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                              data.label
                                            }}</span>
                                        </div>
                                        <v-layout style="max-width: 15vw">
                                          <div :style="{'background-color': data.color}"
                                               style="height: 10px; width: 100%;"/>
                                        </v-layout>
                                        <div class="pl-3" style="width: 90px; text-align: end">
                                          <span style=" width: 90px; color: green">{{
                                              transformNumber((data.value).toFixed(2))
                                            }}</span>
                                        </div>
                                      </v-layout>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                              <div class="px-5 py-5 ml-2"
                                   style="position: relative; flex: 1; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 10px; background-color: #FFFFFF">
                                <v-layout column style="height: 100%">
                                  <v-layout column class="fill-height pt-4">
                                    <div style="flex: none; height: 5vh">
                                      <v-layout>
                                        <span class="pb-3"
                                              style="color: #893FF2; font-size: 1.7vw; font-weight: 800; font-style: italic">Green Density</span>
                                        <v-spacer/>
                                        <b style="color: #893FF2">sqkm</b>
                                      </v-layout>
                                    </div>
                                    <div style="width: 100%; height: 2.5vh" v-for="(data, index) of compareGreenDensity"
                                         :key="index">
                                      <v-layout align-center>
                                        <div style="width: 10vw">
                                          <span :style="{'color': data.color}"
                                                style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                              data.label
                                            }}</span>
                                        </div>
                                        <v-layout style="max-width: 15vw">
                                          <div :style="{'background-color': data.color}"
                                               style="height: 10px; width: 100%;"/>
                                        </v-layout>
                                        <div class="pl-3" style="width: 90px; text-align: end">
                                          <span style=" width: 90px; color: green">{{
                                              transformNumber((data.value).toFixed(2))
                                            }}</span>
                                        </div>
                                      </v-layout>
                                    </div>
                                  </v-layout>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                        <div style="height: 100%; width: 100%;" class="d-flex py-2">
                          <div
                              style="height: 100%; width: 100%; box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33); border-radius: 11px; background-color: #FFFFFF"
                              class="d-flex pa-5">
                            <v-layout column class="fill-height pt-3">
                              <div style="flex: none; height: 5vh">
                                <v-layout>
                                  <span class="pb-3"
                                        style="color: #000000; font-size: 1.7vw; font-weight: bold; font-style: italic">% Change in Green Density</span>
                                  <!--                                    <span class="pb-3" style="color: #000000; font-size: 1.7vw; font-weight: 700; font-style: italic">Compared to {{compareMonth.name}}</span>-->
                                </v-layout>
                              </div>
                              <div style="width: 100%; height: 2.5vh" v-for="(data, index) of dataTableGreenDensity"
                                   :key="index">
                                <v-layout align-center justify-start>
                                  <div style="width: 200px; white-space: nowrap">
                                    <div style="width: 100px; text-align: left">
                                      <span :style="{'color': data.color}"
                                            style="font-size: 0.7vw; width: 130px; color: green; font-weight: bold">{{
                                          data.label
                                        }}</span>
                                    </div>
                                  </div>
                                  <v-layout style="max-width: 15vw" justify-center class="pl-5">
                                    <div :style="{'background-color': data.color}"
                                         style="height: 10px; width: 100%;"></div>
                                  </v-layout>
                                  <div class="pl-3" style="min-width: 70px; text-align: end">
                                       <span :style="{'color': data.change_percent >= 0 ? 'green' : 'red'}"
                                             style="font-size: 0.6vw; color: green; font-weight: bold; width: 200px">
                                         {{ data.value.toFixed(2) == 0.00 ? 'N/A' : data.change_percent.toFixed(2) }}%
                                      </span>
                                    <!--                                      <span v-if="data.change_percent >= 0" class="pl-4" style="font-size: 0.75vw; min-width: 100px; color: green"> INCREASE</span>-->
                                    <!--                                      <span v-else class="pl-4" style="font-size: 0.75vw; min-width: 100px; color: red"> DECREASE</span>-->
                                  </div>
                                  <v-icon v-if="data.change_percent >= 0" color="green">mdi-menu-up</v-icon>
                                  <v-icon v-else color="red">mdi-menu-down</v-icon>
                                </v-layout>
                              </div>
                            </v-layout>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-layout>
              <div style="height: calc(100% - 5px); width: 50%;" class="ml-6 d-flex flex-column">
                <div style="width: 100%; height: 50%; border-top: 1px solid lightgray">
                  <v-layout align-center style="height: 45px; background-color: #f4f3f6" class="px-6 elevation-2">
                    <span class="text-header">Map Overview</span>
                  </v-layout>
                  <div style="height: calc(100% - 45px);">
                    <BaseMap
                        ref="mapView"
                        :dataSource.sync="dataSource"
                        @getData="getLatestData"
                        @gotoMapView="() => $emit('gotoMapView')"
                    />
                  </div>
                </div>
                <div style="width: 100%; height: 50%" id="element-to-print">
                  <v-layout align-center style="height: 45px; flex: none" class="px-6">
                    <span class="text-header" style="font-size: 1.2vw; color: #893FF2">Recorded trends of Green Cover Change</span>
                    <v-spacer/>
                    <v-btn icon small color="green" @click="refreshChart">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-layout>
                  <div class="px-2 flex d-flex" style="height: calc(100% - 45px);">
                    <v-layout class="fill-height">
                      <v-layout class="fill-height pr-3">
                        <canvas style="height: 100%; width: 100%; flex: none" id="myChart"></canvas>
                      </v-layout>
                      <div id="legend-container" style="width: 33%; height: 100%; overflow-y: auto; flex: none"
                           class="pt-3">
                        <v-expansion-panels multiple flat class="legend-panel">
                          <v-expansion-panel
                              class="py-1 px-1"
                              v-for="(group, i) in groupLegend"
                              :key="i"
                          >
                            <v-expansion-panel-header
                                style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25); border-radius: 7px">
                              <v-layout class="fill-height" align-center
                                        :style="{'color': group.legend[0].strokeStyle}">
                                <v-icon size="22" class="mr-1" :color="group.legend[0].strokeStyle">mdi-drag</v-icon>
                                <v-checkbox
                                    @click.native.stop="ChangeDisplayGroupLegend(group)"
                                    class="small-radio"
                                    v-model="group.checkAll"
                                    :color="group.legend[0].strokeStyle">
                                </v-checkbox>
                                {{ group.name }}
                              </v-layout>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-list dense height="100%" class="py-0">
                                <v-list-item v-for="(label, index) of group.legend" :key="'legend-'+index"
                                             style="min-height: 0;height: 30px" class="px-1"
                                             @click="changeDisplayChart(label)">
                                  <v-list-item-action>
                                    <v-icon size="22" v-if="label.hidden" :color="label.fillStyle">
                                      mdi-checkbox-blank-outline
                                    </v-icon>
                                    <v-icon size="22" v-else :color="label.fillStyle">mdi-checkbox-marked</v-icon>
                                  </v-list-item-action>
                                  <!--                                    <v-list-item-action>-->
                                  <!--                                      <div style="width: 20px; height: 20px; max-height: 30px"-->
                                  <!--                                           :style="{'background-color': label.fillStyle, 'border': `2px solid ${label.strokeStyle}`}">-->
                                  <!--                                      </div>-->
                                  <!--                                    </v-list-item-action>-->
                                  <span class="text-truncate" :title="label.text" style="font-size: 12px"
                                        :style="{'color': label.fillStyle, 'text-decoration': label.hidden ? 'line-through' : ''}">{{
                                      label.text
                                    }}</span>
                                  <v-spacer/>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-layout>
          </v-layout>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseMap from "@/components/home/dataSource/BaseMap"
import bbox from '@turf/bbox'
import MonthPicker from "@/components/MonthPicker"
import Chart from "chart.js/auto"
import ConvertMonth from "@/ultis/converMonth"
import {getAOI, getDataDashBoard, getLatestMonth} from "@/backend"
import numberFormat from "@/ultis/comma"


let date = new Date()
export default {
  name: "Datasource",
  components: {MonthPicker, BaseMap},
  data() {
    return {
      years: [],
      tab: 1,
      firstLoad: true,
      loading: false,
      chartLegend: [],
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      fullMonthLabel: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aoi: undefined,
      aois: [],
      compareMonth: {
        "name": "January",
        "full_name": "January 2021",
        "value": "01"
      },
      greenCoverData: {},
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      comparePlantHealth: [],
      compareGreenDensity: [],
      compareForestData: {},
      dataForest: {},
      data: {},
      compareData: {},
      month: [1, 3],
      year: 2021,
      testMonth: 3,
      compareYear: 2021,
      labels: [],
      greenCoverChart: undefined,
      canopyCoverChart: undefined,
      chartFilters: [{
        name: 'DISTRICT',
        code: 'district'
      }, {
        name: 'AOI SELECTED',
        code: 'aoi_selected'
      }],
      chartData: [],
      chartType: 'overall_green_cover',
      imageDataChart: undefined,
      recordedFor: '2021-04',
      compareDate: '2021-01',
      overViews: ['GREEN COVER'],
      myChart: undefined,
    }
  },
  props: {
    dataSource: {}
  },
  mounted() {
  },
  computed: {
    months() {
      return require('@/assets/monthSelect.json')
    },
    groupLegend: function () {
      if (!this.myChart) return []
      let groups = []
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    }
  },
  watch: {
    dataSource(val) {
      this.getLatestData()
    },
    tab (val) {
      this.updateChartData(this.changeDataChart(), this.convertMonth(this.greenCoverData.labels))
      if (val === 1) this.hiddenChart()
    }
  },
  methods: {
    hiddenChart () {
      let plantHealthGroup = this.groupLegend.find(legend => legend.stack === 'Plant health')
      plantHealthGroup.checkAll = false
      this.ChangeDisplayGroupLegend(plantHealthGroup)
      let plantDensityGroup = this.groupLegend.find(legend => legend.stack === 'Plant density')
      plantDensityGroup.checkAll = false
      this.ChangeDisplayGroupLegend(plantDensityGroup)
    },
    changeDataChart () {
      let newData = []
      if (this.tab === 0) {
        newData.push({
          type: 'line',
          label: this.greenCoverData.area_change.label,
          data: this.greenCoverData.area_change.data,
          fill: true,
          pointRadius: 7,
          pointHoverRadius: 7,
          backgroundColor: 'rgba(137,63,242,0.35)',
          borderColor: 'rgb(128,8,250)',
          stack: 'Green cover change'
        })
        newData.push({
          type: 'bar',
          label: this.greenCoverData.green_area.label,
          data: this.greenCoverData.green_area.data,
          barPercentage: 0.8,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          stack: 'Green cover'
        })
      }
      if (this.tab === 1) {
        newData.push({
          type: 'line',
          label: this.greenCoverData.forest_cover_change.label,
          data: this.greenCoverData.forest_cover_change.data,
          fill: true,
          pointRadius: 7,
          pointHoverRadius: 7,
          backgroundColor: 'rgba(137,63,242,0.35)',
          borderColor: 'rgb(128,8,250)',
          stack: 'Forest cover change'
        })
        newData.push({
          type: 'bar',
          label: this.greenCoverData.forest_cover.label,
          data: this.greenCoverData.forest_cover.data,
          barPercentage: 0.8,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          stack: 'Forest cover'
        })
      }

      this.greenCoverData.plant_health.forEach(val => {
        newData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#f76700',
          borderWidth: 1,
          stack: 'Plant health'
        })
      })
      this.greenCoverData.green_density.forEach(val => {
        newData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#434cf6',
          borderWidth: 1,
          stack: 'Plant density'
        })
      })
      return newData
    },
    ChangeDisplayGroupLegend(group) {
      group.legend.forEach(val => {
        val.hidden = group.checkAll
        this.changeDisplayChart(val)
      })
    },
    refreshChart() {
      this.updateChartData(this.changeDataChart(), this.convertMonth(this.greenCoverData.labels))
    },
    transformData() {
      this.chartData = []
      if (this.tab === 0) {
        this.chartData.push({
          type: 'line',
          label: this.greenCoverData.area_change.label,
          data: this.greenCoverData.area_change.data,
          fill: true,
          pointRadius: 7,
          pointHoverRadius: 7,
          backgroundColor: 'rgba(137,63,242,0.35)',
          borderColor: 'rgb(128,8,250)',
          stack: 'Green cover change'
        })
        this.chartData.push({
          type: 'bar',
          label: this.greenCoverData.green_area.label,
          data: this.greenCoverData.green_area.data,
          barPercentage: 0.8,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          stack: 'Green cover'
        })
      }
      if (this.tab === 1) {
        this.chartData.push({
          type: 'line',
          label: this.greenCoverData.forest_cover_change.label,
          data: this.greenCoverData.forest_cover_change.data,
          fill: true,
          pointRadius: 7,
          pointHoverRadius: 7,
          backgroundColor: 'rgba(137,63,242,0.35)',
          borderColor: 'rgb(128,8,250)',
          stack: 'Forest cover change'
        })
        this.chartData.push({
          type: 'bar',
          label: this.greenCoverData.forest_cover.label,
          data: this.greenCoverData.forest_cover.data,
          barPercentage: 0.8,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          stack: 'Forest cover'
        })
      }
      this.greenCoverData.plant_health.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#f76700',
          borderWidth: 1,
          stack: 'Plant health'
        })
      })
      this.greenCoverData.green_density.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#434cf6',
          borderWidth: 1,
          stack: 'Plant density'
        })
      })
    },
    changeDisplayChart(legend) {
      this.myChart.setDatasetVisibility(legend.datasetIndex, legend.hidden);
      this.myChart.update()
    },
    updateChartData(newData, labels) {
      this.myChart.data.datasets = newData
      this.myChart.data.labels = labels
      this.myChart.update()
    },
    displayChart() {
      if (!this.greenCoverData) return
      this.transformData()
      const data = {
        labels: this.convertMonth(this.greenCoverData.labels),
        datasets: this.chartData
      }
      let ctx = document.getElementById('myChart')
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      this.myChart = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            this.updateChartData(this.changeDataChart(), [this.convertMonth(this.greenCoverData.labels)[e.index]])
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'GREEN COVER AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin]
      })
      if (this.tab === 1) this.hiddenChart()
    },
    async getAOI() {
      try {
        this.loading = true
        const res = await getAOI({source: this.dataSource})
        this.aois = res.data.data
        if (!this.aoi) {
          let sing = this.aois.find(val => val.name === 'Singapore')
          if (sing) this.aoi = sing
          else this.aoi = this.aois[1]
        }
        this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    changeAOI() {
      this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)
      this.firstLoad = true
      this.getLatestData()
    },
    async getLatestData () {
      try{
        this.loading = true
        await this.getAOI()
        this.aoi = this.aois.some(aoi => aoi.id === this.aoi.id) ? this.aoi : this.aois[0]
        const res = await getLatestMonth({
          source: this.dataSource,
          aoi_id: this.aoi.id
        })
        this.recordedFor = res.data.data[0] ? res.data.data[0] : ''
        this.compareDate = res.data.data[1] ? res.data.data[1] : res.data.data[0] ? res.data.data[0] : ''
        this.getData()
      } catch (e) {
        console.log(e)
        this.loading = false
      } finally {
      }
    },
    async getData() {
      try {
        this.loading = true
        if (!this.aoi) await this.getAOI()
        let month = null
        month = this.month[0] < 10 ? `0${this.month[0]}` : this.month[0]
        let compareMonth = null
        compareMonth = this.month[1] < 10 ? `0${this.month[1]}` : this.month[1]
        const res = await getDataDashBoard({
          month: this.recordedFor.split("-")[1],
          compare_month: this.compareDate.split("-")[1],
          compare_year: this.compareDate.split("-")[0],
          year: this.recordedFor.split("-")[0],
          source: this.dataSource,
          overview_type: 'overall_green_cover',
          aoi_id: this.aoi.id
        })
        this.dataTablePlantHealth = []
        this.dataTableGreenDensity = []
        this.comparePlantHealth = []
        this.compareGreenDensity = []
        this.greenCoverData = {}

        // let data = res.data.data
        let demoData = res.data.data
        // if (this.firstLoad) {
        //   this.recordedFor = demoData.green_cover_change_in_year.labels.length ? demoData.green_cover_change_in_year.labels[demoData.green_cover_change_in_year.labels.length - 1] : 2
        //   this.compareDate = demoData.green_cover_change_in_year.labels.length ? demoData.green_cover_change_in_year.labels[demoData.green_cover_change_in_year.labels.length - 2 > -1 ? demoData.green_cover_change_in_year.labels.length - 2 : 0 ] : 1
        //   this.getData()
        //   return
        // }
        this.comparePlantHealth = demoData.compare_plant_health
        this.compareGreenDensity = demoData.compare_green_density
        this.dataTablePlantHealth = demoData.plant_health
        this.dataTableGreenDensity = demoData.green_density
        this.greenCoverData = demoData.green_cover_change_in_year
        this.labels = []
        if (!this.myChart) this.displayChart()
        else {
          this.transformData()
          this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
        }
        this.dataForest = demoData.forest_result
        this.compareForestData = demoData.compare_forest_result
        this.data = demoData.result
        this.compareData = demoData.compare_result
        this.$refs.mapView.removeLayer()
        if (demoData.image_ndvi) this.$refs.mapView.createImageLayer(demoData.image_ndvi, false)
        if (demoData.image_mosaic) this.$refs.mapView.createImageLayer(demoData.image_mosaic, false)
        if (demoData.image_density) this.$refs.mapView.createImageLayer(demoData.image_density, true)
        if (demoData.image_classification) this.$refs.mapView.createImageLayer(demoData.image_classification, false)
        if (demoData.image_green_cover_change) this.$refs.mapView.createImageLayer(demoData.image_green_cover_change, false)
        if (demoData.cloud_mask) this.$refs.mapView.createVectorLayer(demoData.cloud_mask, false)
        this.zoomToArea()
      } catch (e) {
        this.loading = false
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    zoomToArea() {
      this.$refs.mapView.submitZoom(bbox(this.aoi.geometry))
    },
    convertMonth(monthArray) {
      return monthArray
    },
    convertMonthText(month) {
      return ConvertMonth.transformMonthText(month)
    },
    transformNumber(number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>
/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}

/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}

/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}

/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}

/deep/
.legend-panel .v-expansion-panel-content__wrap {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-bottom: 4px !important;
  padding-top: 8px !important;
}

/deep/
.legend-panel .v-expansion-panel-header {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border: 1px solid rgba(137, 63, 242, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px !important;
}

/deep/
.legend-panel .v-expansion-panel-header--active {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border-radius: 10px !important;
}

/deep/
.v-select__selection {
  color: #893FF2 !important;
  text-transform: uppercase;
  font-weight: 500;
}

/deep/
.select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
}

/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}
</style>
