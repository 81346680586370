<template>
  <v-dialog
      v-model="downloadDialog"
      max-width="1040"
      persistent
  >
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" width="260" min-height="0" height="31" color="#893FF2" style="background-color: white; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);">
        <span style="color: black">Download data</span>
        <img style="margin-left: 57px" alt="download" :src="require('@/assets/icon/download.svg')">
      </v-btn>
    </template>

    <v-card
      v-if="currentUser.role != 'SUBMEMBER'"
    >
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">DOWNLOAD DATA</span>
        <v-spacer/>
        <v-btn icon small @click="downloadDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-layout align-center justify-center style="padding: 20px 0 20px 0">
          <img alt="download" :src="require('@/assets/icon/union.svg')">
        </v-layout>
        <v-layout justify-center wrap style="padding: 0 20px 50px 20px">
          <div @click="selectImage('result')" class="ma-2" style="width: 220px; height: 220px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
            <div style="width: 100%; height: 170px; cursor: pointer">
              <img :src="require('@/assets/images/NDVI.png')" width="100%" height="170px" style="max-height: 800px; object-fit: cover">
            </div>
            <div class="d-flex align-center justify-center" style="width: 100%; height: 50px; box-shadow: rgb(0 0 0 / 22%) 0 1px 0 0 inset;">
              <span style="font-size: 14px; color: #000000; cursor: pointer">ANALYTICS IMAGE DOWNLOAD</span>
            </div>
          </div>
          <div @click="selectImage('input')" class="ma-2" style="cursor: pointer; width: 220px; height: 220px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
            <div style="width: 100%; height: 170px; cursor: pointer">
              <img :src="require('@/assets/images/singapore.jpg')" width="100%" height="170px" style="max-height: 800px; object-fit: cover">
            </div>
            <div class="d-flex align-center justify-center" style="width: 100%; height: 50px; box-shadow: rgb(0 0 0 / 22%) 0 1px 0 0 inset;">
              <span style="font-size: 14px; color: #000000; cursor: pointer">MOSAIC IMAGE DOWNLOAD</span>
            </div>
          </div>
          <div @click="selectVector()" class="ma-2" style="cursor: pointer; width: 220px; height: 220px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
            <div style="width: 100%; height: 170px; cursor: pointer">
              <img alt="vector" :src="require('@/assets/images/singapore-image-vector.png')" width="100%" height="170px" style="max-height: 800px; object-fit: cover">
            </div>
            <div class="d-flex align-center justify-center" style="width: 100%; height: 50px; box-shadow: rgb(0 0 0 / 22%) 0 1px 0 0 inset;">
              <span style="font-size: 14px; color: #000000; cursor: pointer">VECTOR DOWNLOAD</span>
            </div>
          </div>
          <div @click="openDownloadPdfForm" class="ma-2" style="width: 220px; height: 220px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
            <div style="width: 100%; height: 170px;" class="pt-3">
              <img alt="report" :src="require('@/assets/images/pdf-logo.png')" width="100%" height="100%" style="max-height: 800px; object-fit: contain">
            </div>
            <div class="d-flex align-center justify-center" style="width: 100%; height: 50px; box-shadow: rgb(0 0 0 / 22%) 0 1px 0 0 inset;">
              <span style="font-size: 14px; color: #000000">REPORT DOWNLOAD</span>
            </div>
          </div>
        </v-layout>
      </v-card-text>
      <SelectImage ref="selectImageForm"/>
      <SelectVector ref="selectVectorForm"/>
      <SelectPdf ref="PdfForm"/>
      <ExportPDF ref="exportPdf" @setLoading="val => loading = val"/>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectImage from "@/components/download/SelectImageDownload";
import SelectVector from "@/components/download/SelectVectorDownload";
import ExportPDF from "@/components/download/pdf/DownloadPDF";
import SelectPdf from "@/components/download/pdf/SelectPdfDownload";
import { mapState } from "@/store/ults";

export default {
  name: "PopupDownload",
  components: {SelectPdf, ExportPDF, SelectVector, SelectImage},
  data () {
    return {
      loading: false,
      downloadDialog: false
    }
  },
  methods: {
    openDownloadPdfForm () {
      this.$refs.exportPdf.dialog = true
      // this.$refs.PdfForm.openDialog()
    },
    selectImage (type) {
      this.$refs.selectImageForm.openDialog(type)
    },
    selectVector () {
      this.$refs.selectVectorForm.openDialog()
    }
  },
  computed: {
    ...mapState("main", ["menu"]),
    ...mapState("auth", ["currentUser"]),
  },
}
</script>

<style scoped>

</style>
