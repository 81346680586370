import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/MainLayout.vue'
import NotFound from '../views/NotFound'
import {checkAuth} from './auth'
import LandingPage from "@/views/landingPage/LandingPage";
import SignIn from "@/views/signIn/SignIn";
import ExportPDF from "@/components/download/pdf/ExportPDF";
import ForgotPassword from "@/views/forgotPassword/ForgotPassword";
Vue.use(VueRouter)

const routes = [
  {
    path: '/export',
    component: ExportPDF,
  },
  {
    path: '/',
    component: SignIn,
  },
  {
    path: '/signIn',
    component: SignIn,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
  },
  {
    beforeEnter: checkAuth,
    path: '/app',
    component: MainLayout,
    children: [
      {
        path: 'image-detail',
        name: 'Image detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Imagery.vue')
      },
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      }, {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/MyUser.vue')
      }, {
        path: 'userManagement',
        name: 'User Management',
        component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')
      }, {
        path: 'activityLog',
        name: 'Activity Log',
        component: () => import(/* webpackChunkName: "about" */ '../views/ActivityLog.vue')
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'Not Found'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
