<template>
  <v-dialog v-if="dialog" v-model="dialog" width="800px" persistent>
    <div style="background-color: white">
      <v-overlay :value="loading">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title>
        <v-spacer/>
        <v-btn icon small @click="dialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>
      <v-card-title>
        <v-layout class="fill-height ma-0 pa-0" align-center justify-end>
          <div class="ml-2">
            <small style="">Select report</small>
            <v-select height="40" hide-details solo dense v-model="tab" :items="tabs" item-text="name"
                      item-value="id"></v-select>
          </div>
          <div class="ml-2">
            <small style="">Select Month</small>
            <MonthPicker left label="Current month" v-model="recordedFor"/>
          </div>
          <div class="ml-2 pt-9">
            <v-btn color="accent" @click="getData">Export</v-btn>
          </div>
        </v-layout>
      </v-card-title>
      <div id="test" style="max-height: 65vh; overflow-y: auto; width: 100%; overflow-x: hidden">
        <preview
            ref="preview"
            @closePopup="dialog = false"
            :reportType.sync="reportType"
            :report-date.sync="reportDate"
            :compareDate.sync="compareDate"
            :month.sync="currentMonth"
            :tab.sync="tab"
            :data.sync="data"
            :compareData.sync="compareData"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import MonthPicker from "@/components/MonthPicker"
import Preview from "./Preview"
import {getDataDashBoard, getImageries} from "@/backend"
import Chart from "chart.js/auto";
import ConvertMonth from "@/ultis/converMonth"
import numberFormat from "@/ultis/comma"
import sleep from "../../../ultis/sleep";
import randomColor from "@/ultis/randomColor";

export default {
  name: "DownloadPDF",
  components: {Preview, MonthPicker},
  data() {
    return {
      data: {},
      tabs: [{
        name: 'Green cover',
        id: 0
      }, {
        name: 'Forest cover',
        id: 1
      }],
      tab: 0,
      dialog: false,
      recordedFor: '2021-04',
      loading: false,
      greenCoverData: {},
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      comparePlantHealth: [],
      compareGreenDensity: [],
      compareForestData: {},
      dataForest: {},
      labels: [],
      compareData: {},
      month: 12,
      myChart: undefined,
      myChart2: undefined,
      myChart3: undefined,
      chartLegend: [],
      chartLegend2: [],
      chartLegend3: [],
    }
  },
  computed: {
    currentMonth() {
      return this.recordedFor.split("-")[1]
    },
    reportDate() {
      return this.convertMonthText(this.recordedFor.split("-")[1]) + `/${this.recordedFor.split("-")[0]}`
    },
    compareDate() {
      let currentMonth = parseInt(this.recordedFor.split("-")[1])
      let currentYear = parseInt(this.recordedFor.split("-")[0])
      if (currentMonth > 1) return this.convertMonthText(currentMonth - 1 < 10 ? `0${currentMonth - 1}` : currentMonth - 1) + `/${this.recordedFor.split("-")[0]}`
      else return this.convertMonthText('12') + `/${currentYear - 1}`
    },
    reportType() {
      return this.tabs.find(val => val.id === this.tab).name.toUpperCase()
    },
    groupLegend: function () {
      if (!this.myChart) return []
      let groups = []
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    },
    groupLegend2: function () {
      if (!this.myChart2) return []
      let groups = []
      this.myChart2.data.datasets.forEach(dataset => {
        this.chartLegend2.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    },
    groupLegend3: function () {
      if (!this.myChart3) return []
      let groups = []
      this.myChart3.data.datasets.forEach(dataset => {
        this.chartLegend3.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    }
  },
  mounted() {
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.myChart.destroy();
        this.myChart = undefined

        this.myChart2.destroy();
        this.myChart2 = undefined

        this.myChart3.destroy();
        this.myChart3 = undefined
      }
    }
  },
  methods: {
    async getImages() {
      try {
        this.loading = true
        let currentMonth = parseInt(this.recordedFor.split("-")[1])
        let currentYear = parseInt(this.recordedFor.split("-")[0])
        let fromMonth
        if (currentMonth > 1) {
          fromMonth = `${this.recordedFor.split("-")[0]}` + `-0${currentMonth - 1}`
        } else {
          fromMonth = `${currentYear - 1}` + '-12'
        }
        const res = await getImageries({
          dateRanger: [fromMonth, this.recordedFor],
          source: 'sentinel'
        })
        let data = res.data.data
        data.forEach(image => {
          image['selected'] = true
          image['layoutRight'] = true
          image['opacity'] = 1
          image['layoutLeft'] = true
          image['displayLabel'] = true
          image['map_type'] = image.data_type
          image['color'] = randomColor.getColor()
          if (image.list_values) image.list_values.forEach(value => {
            value['display'] = true
          })
        })
        this.$refs.preview.addLayer(data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData() {
      try {
        await this.getImages()
        this.loading = true
        let currentMonth = parseInt(this.recordedFor.split("-")[1])
        let currentYear = parseInt(this.recordedFor.split("-")[0])
        let compareMonth = 12
        let compareYear = 2021
        if (currentMonth > 1) {
          compareMonth = currentMonth - 1 < 10 ? `0${currentMonth - 1}` : currentMonth - 1
          compareYear = this.recordedFor.split("-")[0]
        } else compareYear = currentYear - 1
        const res = await getDataDashBoard({
          month: this.recordedFor.split("-")[1],
          compare_month: 1,
          compare_year: this.recordedFor.split("-")[0],
          year: this.recordedFor.split("-")[0],
          source: 'sentinel',
          overview_type: 'overall_green_cover',
          aoi_id: 14
        })
        const resCompare = await getDataDashBoard({
          month: this.recordedFor.split("-")[1],
          compare_month: compareMonth,
          compare_year: compareYear,
          year: this.recordedFor.split("-")[0],
          source: 'sentinel',
          overview_type: 'overall_green_cover',
          aoi_id: 14
        })

        if(this.tab === 0)
         this.compareData = resCompare.data.data.result
        else
          this.compareData = resCompare.data.data.forest_result

        this.dataTablePlantHealth = []
        this.dataTableGreenDensity = []
        this.comparePlantHealth = []
        this.compareGreenDensity = []
        this.greenCoverData = {}

        let demoData = res.data.data

        this.comparePlantHealth = demoData.compare_plant_health
        this.compareGreenDensity = demoData.compare_green_density
        this.dataTablePlantHealth = demoData.plant_health
        this.dataTableGreenDensity = demoData.green_density
        this.greenCoverData = demoData.green_cover_change_in_year
        this.labels = []
        if (!this.myChart) this.displayChart()
        else {
          this.transformData()
          this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
        }
        this.dataForest = demoData.forest_result
        this.compareForestData = demoData.compare_forest_result
        this.data = demoData.result
        await sleep(300)
        this.loading = false
        let objDiv = document.getElementById("test");
        objDiv.scrollTop = objDiv.scrollHeight;
        await sleep(500)
        await this.$refs.preview.getPDF()
      } catch (e) {
        console.log(e)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    displayChart(tab = 0, isChange = false) {
      if (!this.greenCoverData) return
      this.transformData()
      const data = {
        labels: this.convertMonth(this.greenCoverData.labels),
        datasets: this.chartData
      }
      let ctx1 = document.getElementById('chart1')
      const htmlLegendPlugin1 = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      this.myChart = new Chart(ctx1, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            this.updateChartData(this.changeDataChart(), [this.convertMonth(this.greenCoverData.labels)[e.index]])
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'GREEN COVER AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin1]
      })

      let ctx2 = document.getElementById('chart2')
      const htmlLegendPlugin2 = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend2 = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      this.myChart2 = new Chart(ctx2, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            this.updateChartData(this.changeDataChart(), [this.convertMonth(this.greenCoverData.labels)[e.index]])
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'GREEN COVER AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin2]
      })


      let ctx3 = document.getElementById('chart3')
      const htmlLegendPlugin3 = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend3 = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      this.myChart3 = new Chart(ctx3, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            this.updateChartData(this.changeDataChart(), [this.convertMonth(this.greenCoverData.labels)[e.index]])
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'GREEN COVER AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin3]
      })
      this.hiddenChart(tab, isChange)
    },
    hiddenChart(tab = 0, isChange = false) {
      let plantHealthGroup = this.groupLegend.find(legend => legend.stack === 'Plant health')
      plantHealthGroup.checkAll = false
      this.ChangeDisplayGroupLegend(plantHealthGroup, 'myChart')
      let plantDensityGroup = this.groupLegend.find(legend => legend.stack === 'Plant density')
      plantDensityGroup.checkAll = false
      this.ChangeDisplayGroupLegend(plantDensityGroup, 'myChart')

      let plantHealthGroup2 = this.groupLegend2.find(legend => legend.stack === 'Plant health')
      plantHealthGroup2.checkAll = false
      this.ChangeDisplayGroupLegend(plantHealthGroup2, 'myChart2')
      let plantDensityGroup2 = this.groupLegend2.find(legend => legend.stack === 'Plant density')
      plantDensityGroup2.checkAll = false
      this.ChangeDisplayGroupLegend(plantDensityGroup2, 'myChart2')

      let plantHealthGroup3 = this.groupLegend3.find(legend => legend.stack === 'Plant health')
      plantHealthGroup3.checkAll = false
      this.ChangeDisplayGroupLegend(plantHealthGroup3, 'myChart3')
      let plantDensityGroup3 = this.groupLegend3.find(legend => legend.stack === 'Plant density')
      plantDensityGroup3.checkAll = false
      this.ChangeDisplayGroupLegend(plantDensityGroup3, 'myChart3')
      switch (tab) {
        case 0:
          let greenCoverChange = this.groupLegend.find(legend => legend.stack === 'Green cover change')
          greenCoverChange.checkAll = false
          this.ChangeDisplayGroupLegend(greenCoverChange, 'myChart')
          let greenCover = this.groupLegend2.find(legend => legend.stack === 'Green cover')
          greenCover.checkAll = false
          this.ChangeDisplayGroupLegend(greenCover, 'myChart2')
          break
        case 1:
          let forestCoverChange = this.groupLegend.find(legend => legend.stack === 'Forest cover change')
          forestCoverChange.checkAll = isChange
          this.ChangeDisplayGroupLegend(forestCoverChange)
          let forestCover = this.groupLegend.find(legend => legend.stack === 'Forest cover')
          forestCover.checkAll = !isChange
          this.ChangeDisplayGroupLegend(forestCover)
          break
      }
    },
    ChangeDisplayGroupLegend(group, chart) {
      group.legend.forEach(val => {
        val.hidden = group.checkAll
        this.changeDisplayChart(val, chart)
      })
    },
    transformData(tab = 0) {
      this.chartData = []
      switch (tab) {
        case 0:
          this.chartData.push({
            type: 'line',
            label: this.greenCoverData.area_change.label,
            data: this.greenCoverData.area_change.data,
            fill: true,
            pointRadius: 7,
            pointHoverRadius: 7,
            backgroundColor: 'rgba(137,63,242,0.35)',
            borderColor: 'rgb(128,8,250)',
            stack: 'Green cover change'
          })
          this.chartData.push({
            type: 'bar',
            label: this.greenCoverData.green_area.label,
            data: this.greenCoverData.green_area.data,
            barPercentage: 0.8,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            stack: 'Green cover'
          })
          break
        case 1:
          this.chartData.push({
            type: 'line',
            label: this.greenCoverData.forest_cover_change.label,
            data: this.greenCoverData.forest_cover_change.data,
            fill: true,
            pointRadius: 7,
            pointHoverRadius: 7,
            backgroundColor: 'rgba(137,63,242,0.35)',
            borderColor: 'rgb(128,8,250)',
            stack: 'Forest cover change'
          })
          this.chartData.push({
            type: 'bar',
            label: this.greenCoverData.forest_cover.label,
            data: this.greenCoverData.forest_cover.data,
            barPercentage: 0.8,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            stack: 'Forest cover'
          })
          break
      }
      this.greenCoverData.plant_health.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#f76700',
          borderWidth: 1,
          stack: 'Plant health'
        })
      })
      this.greenCoverData.green_density.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#434cf6',
          borderWidth: 1,
          stack: 'Plant density'
        })
      })
    },
    changeDisplayChart(legend, chart = 'myChart') {
      this[chart].setDatasetVisibility(legend.datasetIndex, legend.hidden);
      this[chart].update()
    },
    updateChartData(newData, labels) {
      this.myChart.data.datasets = newData
      this.myChart.data.labels = labels
      this.myChart.update()
    },
    convertMonth(monthArray) {
      return monthArray
    },
    convertMonthText(month) {
      return ConvertMonth.transformMonthText(month)
    },
    transformNumber(number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>

</style>
