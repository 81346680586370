<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="600">
    <v-card :loading="loading">
      <v-card-title>
        Change Password
      </v-card-title>
      <v-card-text>
        <v-form ref="formData" v-model="valid">
          <v-layout justify-center column align-center class="mb-3">
            <h3 style="color: red">
              You need to update your password!
            </h3>
            <span style="font-size: 10.5px">
            (For security, you need to update your password the first time you log in or after 12 months of using the system.)
          </span>
          </v-layout>
          <v-text-field outlined dense v-model="form.password" label="Password" :rules="[rules.requiredPassword]"
                        :type="isShowPassword ? 'text' : 'password'"
                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="isShowPassword = !isShowPassword">
          </v-text-field>
          <v-text-field outlined dense v-model="form.password_confirmation" label="Confirm Password"
                        :rules="[rules.requiredConfirmPassword]"
                        :type="isShowConfirmPassword ? 'text' : 'password'"
                        :disabled="!this.form.password"
                        :append-icon="isShowConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="isShowConfirmPassword = !isShowConfirmPassword">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="#893FF2" dark width="80" @click="updatePassWord">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {updateProfile, verifyUser} from "@/backend";

export default {
  name: "ChangePassword",
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      isShowPassword: false,
      isShowConfirmPassword: false,
      form: {
        password: undefined,
        password_confirmation: undefined
      },
      rules: {
        requiredPassword: value => (!!value && !!value.trim() && /^(?=.*[!,@,#,$,%,^,&,(,),{,},\[\],:,;,<,>,?,/,~,_,+,-,=,-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(value)) || 'At least:   - 12 characters  - 1 lowercase  - 1 uppercase  - 1 number  - 1 special characters (!, $, #, %, ...);',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.form.password.trim()) || 'Confirm Password is invalid',
      }
    }
  },
  methods: {
    async updatePassWord() {
      try {
        this.loading = true
        await verifyUser(this.form)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        location.reload()
        this.loading = false
        this.dialog = false
      } catch (e) {
        // this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>