<template>
  <section style="width: 100%" id="pdf-content">
    <div style="display: flex; flex-direction: column; padding: 0 20px;">
      <div style="display: flex; flex-direction: column; height: 1122px; max-width: 100%; position: relative; overflow: hidden;" class="pdf-item">
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">NPARKS MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED: {{reportDate}}
          </div>
        </div>
        <div
            style="width: 100%; height: 70px; display: flex;justify-content: space-between;margin-top: 40px; padding: 0px 70px;">
          <img alt="report" :src="require('@/assets/images/logo.png')" max-width="100%" height="100%"
               style="max-height: 800px; object-fit: contain;">
          <img alt="report" :src="require('@/assets/images/NParks_Logo.png')" max-width="100%" height="100%"
               style="max-height: 800px; object-fit: contain">
        </div>
        <div style="width:100%; height: 400px; z-index: 1;margin-top: 120px; padding: 0px 70px">
          <h1 style="font-size: 250%;">
            {{reportType}} CHANGE MONITORING REPPORT
          </h1>
          <h3 style="margin-top: 50px; margin-bottom: 15px;">
            CONTENTS
          </h3>
          <ul style=" list-style-type: none; padding-left: 0px !important;">
            <li>1. SUBSCRIPTION DETAILS</li>
            <li>2. {{reportType}} MONTH OVERVIEW</li>
            <li>3. {{reportType}} CHANGE & SATELLITE MOSAIC COMPARISON</li>
            <li>4. {{reportType}} CHANGE STATISTICS</li>
            <li>5. {{reportType}} CHANGE, STATELLITE MOSAIC, CLOUD COVER</li>
          </ul>
        </div>
        <img alt="report" :src="require('@/assets/images/background-login.png')" max-width="100%" height="60%"
             style="max-height: 800px; object-fit: cover;position: absolute; bottom: 2px;z-index: 0;">
      </div>
      <!-- NEXT PAGE -->
      <v-layout style="height: 1122px; width: 100%" class="pdf-item" column>
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <v-layout style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">NPARKS MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED: {{reportDate}}
          </v-layout>
        </div>
        <div class="flex" style="display: flex; flex: 1; padding: 10px; flex-direction: column">
          <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <v-layout class="fill-height" align-center style="display: flex; align-items: center">
              SUBSCRIPTION DETAILS
            </v-layout>
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px; border: 1px solid lightgray">
            <GreenCover ref="mapView1" :index="1"/>
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <v-layout style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">SUBSCRIBED
                AREA</span>
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: green; margin-left: 30px">AOI of Singapore</span>
              <v-spacer/>
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: green; ">781.94 SQKM</span>
            </v-layout>
          </div>
        </div>
        <div
            style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px; flex: none">
          <v-layout class="fill-height" align-center style="display: flex; align-items: center">
            MONTH OVERVIEW
          </v-layout>
        </div>
        <div style="height: 100%; flex: 1; display: flex; flex-direction: column; padding: 20px">
          <div style="flex: 1; display: flex; height: 100%">
            <div style="flex: 2; height: 100%">
              <div style="height: 100%; display: flex; flex-direction: column">
                <div style="flex: none; height: 30px; width: 100%">
                  <div style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px;">
                    <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Recorded
                      trends of Green Cover Change in a Year</span>
                  </div>
                </div>
                <div style="display: flex; height: 100%;">
                  <v-layout class="fill-height">
                    <v-layout class="fill-height pr-3">
                      <canvas style="height: 100%; width: 100%; flex: none" id="chart1"></canvas>
                    </v-layout>
                    <div style="width: 33%; height: 100%; overflow-y: auto; flex: none"
                         class="pt-3">
                      <div style="display: flex; height: 100%; flex-direction: column">
                        <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                          <div style="display: flex; height: 100%; align-items: center">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                              <path fill="#36A2EB" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
                            </svg>
                            <span style="color: #36A2EB">{{tab === 0 ? 'Green cover' : 'Forest cover'}}</span>
                          </div>
                        </div>
                        <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                          <div style="display: flex; height: 100%; align-items: center">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                              <path fill="#7a78e6" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                            </svg>
                            <span style="color: #7a78e6">{{tab === 0 ? 'Green cover change' : 'Forest cover change'}}</span>
                          </div>
                        </div>
                        <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                          <div style="display: flex; height: 100%; align-items: center">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                              <path fill="#F76700" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                            </svg>
                            <span style="color: #F76700">Plant health</span>
                          </div>
                        </div>
                        <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                          <div style="display: flex; height: 100%; align-items: center">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                              <path fill="#434CF6" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                            </svg>
                            <span style="color: #434CF6">Plant density</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1; display: flex">
            <div style="flex: 1; display: flex; flex-direction: column">
              <div style="flex: none; height: 30px; width: 100%">
                <div style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px">
                  <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Recorded
                    trends of Green Cover Change in a Year</span>
                </div>
              </div>
              <div style="display: flex; height: 100%;">
                <v-layout class="fill-height">
                  <v-layout class="fill-height pr-3">
                    <canvas style="height: 100%; width: 100%; flex: none" id="chart2"></canvas>
                  </v-layout>
                  <div style="width: 33%; height: 100%; overflow-y: auto; flex: none"
                       class="pt-3">
                    <div style="display: flex; height: 100%; flex-direction: column">
                      <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                        <div style="display: flex; height: 100%; align-items: center">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                            <path fill="#36A2EB" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                          </svg>
                          <span style="color: #36A2EB">{{tab === 0 ? 'Green cover' : 'Forest cover'}}</span>
                        </div>
                      </div>
                      <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                        <div style="display: flex; height: 100%; align-items: center">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                            <path fill="#7a78e6" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
                          </svg>
                          <span style="color: #7a78e6">{{tab === 0 ? 'Green cover change' : 'Forest cover change'}}</span>
                        </div>
                      </div>
                      <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                        <div style="display: flex; height: 100%; align-items: center">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                            <path fill="#F76700" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                          </svg>
                          <span style="color: #F76700">Plant health</span>
                        </div>
                      </div>
                      <div style="width: 100%; height: 30px; flex: none; border: 1px solid #7a78e6; border-radius: 8px; margin: 8px 0; padding: 0 8px">
                        <div style="display: flex; height: 100%; align-items: center">
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24" class="mr-3">
                            <path fill="#434CF6" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
                          </svg>
                          <span style="color: #434CF6">Plant density</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-layout>
              </div>
            </div>
          </div>
        </div>
      </v-layout>
      <!-- NEXT PAGE -->

      <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
        <v-layout style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
          <span style="font-size: 28px; font-style: italic; font-weight: bold">NPARKS MONTHLY REPORT</span>
          <v-spacer/>
          REPORT DATED: {{reportDate}}
        </v-layout>
      </div>
      <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
        <div style="display: flex; height: 100%; align-items: center">
          {{reportType}} CHANGE STATISTIC
        </div>
      </div>
      <div style="width: 100%; height: 250px; display: flex; margin-top: 65px; margin-bottom: 50px">
        <div style="height: 100%; flex: 1;" class="pr-3">
          <div style="height: 100%; flex: 1; flex-direction: column; padding: 0 8px">
            <GreenCoverV2 ref="mapView2" :index="2"/>
            <div style="flex: none; min-height: 30px;border: 1px solid #7a78e6;">
              <div style="display: flex; height: 100%; align-content: center">
                <b style="color: #7a78e6">{{reportDate}}</b>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 100%; flex: 1;" class="pl-3">
          <div style="height: 100%; flex: 1; flex-direction: column; padding: 0 8px">
            <PlantHealth ref="mapView3" :index="3"/>
            <div style="flex: none; min-height: 30px;border: 1px solid #7a78e6;" class="px-2">
              <div style="display: flex; height: 100%">
                <b style="color: #7a78e6">{{compareDate}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px; margin-top: 30px;">
           <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Recorded trends of Green Cover Change in a Year</span>
      </div>

      <canvas style="height: 300px; width: 700px; flex: none" id="chart3"></canvas>

      <div style="height: 55%; overflow: hidden; width: 100%; padding: 35px 40px;">
          <div style="display: flex;align-items:center">
            <div style="width: 40%;">
              <span style="font-size: 400%">
                {{
                  (compareData && compareData.area_percent_change) ? Math.abs(parseFloat(compareData.area_percent_change).toFixed(2)) : ''
                }}%
              </span>
            </div>
            <div style="width: 50%; ">
              <span>
                <span style="font-weight: bold;">
                  CHANGE IN {{reportType}} AREA
                </span>
                <br>
                <span style="font-weight: bold; color: red; text-transform: lowercase">
                  {{
                    (compareData && compareData.area_change) ? transformNumber((compareData.area_change).toFixed(2)) : 0
                  }} sqkm
                </span>
                in {{reportType ? reportType.toLowerCase() : ''}} area compared to <span style="font-weight: bold; color: red">
                {{ compareDate }}
              </span> record
              </span>
            </div>
          </div>
        </div>

      <div style="margin: 50px 0; display: flex; align-items: center;">
        <div style="width: 40%;">
          <small style="width: 100%; display: flex; justify-content:center">
            % OF {{reportType ? reportType.toUpperCase() : ''}} CLEARANCE DETECTED =
          </small>
        </div>
        <div style="width: 50%; align-items: center; ">
          <div style="border-bottom: 1px solid black; padding: 4px;">
            <small style="width: 100%; display: flex; justify-content:center">
              AREA OF {{reportType ? reportType.toUpperCase() : ''}} CLEARD
            </small>
          </div>
          <div style=" padding: 4px;">
            <small style="width: 100%; display: flex; justify-content:center">
              TOTAL AREA OF {{reportType}} LAYER FOR THE MONTH
            </small>
          </div>
        </div>
      </div>

      <!-- NEXT PAGE -->
      <v-layout style="height: 1122px; width: 100%" class="pdf-item" column>
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <v-layout style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">NPARKS MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED: {{reportDate}}
          </v-layout>
        </div>
        <div style="width: 100%; height: 100%; background-color: #7a78e6">
          <plant-health-v2 ref="mapView4"/>
        </div>
      </v-layout>
      <v-layout style="height: 1122px; width: 100%" class="pdf-item" column>
        <div style="flex: none; height: 60px; width: 100%; background-color: #EBE3ED">
          <v-layout style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">NPARKS MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED: {{reportDate}}
          </v-layout>
        </div>
        <div style="width: 100%; height: 100%; background-color: #7a78e6">
          <plant-health-v3 ref="mapView5"/>
        </div>
      </v-layout>
    </div>
  </section>
</template>

<script>
import GreenCover from "@/components/download/pdf/maps/GreenCover";
import GreenCoverV2 from "@/components/download/pdf/maps/GreenCoverV2";
import PlantHealth from "@/components/download/pdf/maps/PlantHealth";
import PlantHealthV2 from "@/components/download/pdf/maps/PlantHealthV2";
import PlantHealthV3 from "@/components/download/pdf/maps/PlantHealthV3";
import AOI from "@/components/download/pdf/maps/AOI";
import Datasource from "@/components/home/dataSource";
import VueHtml2pdf from "vue-html2pdf";
import {getAOI, getGeom} from "@/backend";
import MonthTransform from "@/ultis/converMonth";
import numberFormat from "@/ultis/comma";
import html2pdf from "html2pdf.js"
import BaseMap from "@/components/download/pdf/maps/BaseMap"
import sleep from "../../../ultis/sleep";
import bbox from "@turf/bbox";
import ConvertMonth from "@/ultis/converMonth"

export default {
  name: "Preview",
  components: {
    BaseMap,
    GreenCover,
    GreenCoverV2,
    PlantHealth,
    PlantHealthV2,
    PlantHealthV3,
    AOI,
    Datasource,
    VueHtml2pdf
  },
  data() {
    return {
      loading: false,
      greenCover1: false,
      greenCover2: false,
      plantHealth: false,
      plantHealth2: false,
      plantHealth3: false,
      chartLegend: [],
      chartMonth: undefined,
      myChart: undefined,
      greenCoverChart: undefined,
      greenDensityChart: undefined,
      aoi: undefined,
      greenCoverData: {},
      chartData: [],
      labels: [],
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      aois: [],
    }
  },
  props: {
    compareData: {type: Object, default: () => {}},
    data: {type: Object, default: () => {}},
    tab: {type: Number, default: 0},
    month: {type: String, default: ''},
    compareDate: {type: String, default: ''},
    reportDate: {type: String, default: ''},
    reportType: {type: String, default: 'GREEN COVER'}
  },
  mounted() {
  },
  computed: {
    groupLegend() {
      if (!this.myChart) return []
      let groups = []
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      return groups
    }
  },
  methods: {
    convertMonthText(month) {
      console.log(month)
      return ConvertMonth.transformMonthText(month)
    },
    async addLayer(data) {
      let compareMonth = 12
      let currentMonth = parseInt(this.month)
      if (currentMonth > 1) compareMonth = currentMonth-1 < 10 ? `0${currentMonth-1}` : currentMonth-1
      let mosaic = data.find(val => val.month === this.month && val.type === "mosaic")
      this.addMap1(mosaic)
      this.addMap2(mosaic)
      this.addMap3(mosaic)
      this.addMap4(mosaic)
      this.addMap5(mosaic)


      if (this.tab === 0) {
        let currentGreenCover = data.find(val => val.month === this.month && val.type === "classification")
        let currentGreenCoverChange = data.find(val => val.month === this.month && val.type === "green_cover_change")
        await sleep(100)
        this.addMap1(currentGreenCover)
        this.addMap4(currentGreenCoverChange)
        this.addMap2(currentGreenCoverChange)
        this.addMap3(data.find(val => val.month === compareMonth && val.type === "green_cover_change"))
      } else {
        let currentForestCover = data.find(val => val.month === this.month && val.type === "forest_cover_area")
        let currentForestCoverChange = data.find(val => val.month === this.month && val.type === "forest_cover_change")
        await sleep(100)
        this.addMap1(currentForestCover)
        this.addMap4(currentForestCoverChange)
        this.addMap2(currentForestCoverChange)
        this.addMap3(data.find(val => val.month === compareMonth && val.type === "forest_cover_change"))
      }
      let cloud = data.find(val => val.month === this.month && val.type === "cloud")
      try {
        this.loading = true
        const res = await getGeom(cloud.id)
        cloud.geometry = res.data.data
        cloud.bbox = bbox(res.data.data)
        this.$refs.mapView5.addVectorLayer(cloud)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addMap1(image) {
      if (!this.$refs.mapView1 || !this.$refs.mapView1.loaded()) {
        await sleep(100)
        await this.addMap1(image)
      } else this.$refs.mapView1.addImageLayer(image)
    },
    async addMap2(image) {
      if (!this.$refs.mapView2 || !this.$refs.mapView2.loaded()) {
        await sleep(100)
        await this.addMap2(image)
      } else this.$refs.mapView2.addImageLayer(image)
    },
    async addMap3(image) {
      if (!this.$refs.mapView3 || !this.$refs.mapView3.loaded()) {
        await sleep(100)
        await this.addMap3(image)
      } else this.$refs.mapView3.addImageLayer(image)
    },
    async addMap5(image) {
      if (!this.$refs.mapView5 || !this.$refs.mapView5.loaded()) {
        await sleep(100)
        await this.addMap5(image)
      } else this.$refs.mapView5.addImageLayer(image)
    },
    async addMap4(image) {
      if (!this.$refs.mapView4 || !this.$refs.mapView4.loaded()) {
        await sleep(100)
        await this.addMap4(image)
      } else this.$refs.mapView4.addImageLayer(image)
    },
    async getAOI() {
      try {
        this.loading = true
        const res = await getAOI()
        this.aois = res.data.data
        if (!this.aoi) {
          let sing = this.aois.find(val => val.name === 'AOI Entire Singapore')
          if (sing) this.aoi = sing
          else this.aoi = this.aois[1]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    convertMonth(monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    transformNumber(number) {
      return numberFormat.numberWithCommas(number)
    },
    async getPDF() {
      try {
        await sleep(500)
        let element = document.getElementById('pdf-content');
        var opt = {
          margin: 0,
          filename: 'report.pdf',
          jsPDF: {orientation: 'portrait'}
        };

        html2pdf().set(opt)
            .from(element)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
              const totalPages = pdf.internal.getNumberOfPages()
              for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(150)
                pdf.text(
                    'Page ' + i + ' | ' + totalPages,
                    pdf.internal.pageSize.getWidth() * 0.88,
                    pdf.internal.pageSize.getHeight() - 2
                )
              }
            })
            .save();
        await sleep(500)
        this.$emit('closePopup')
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
  }
}
</script>

<style scoped>

</style>
