<template>
  <v-app>
    <Message/>
    <div style="height: 100vh">
      <div class="d-flex flex-column" style="height: 100%;">
<!--        <div-->
<!--            style="flex: none; width: 100%; height: 76px; background: linear-gradient(180deg, rgba(208, 202, 216, 0.8) 0%, rgba(228, 216, 227, 0.8) 41.55%, rgba(231, 223, 232, 0.8) 72.28%, rgba(231, 227, 233, 0.8) 100%); flex: none">-->
<!--          <v-layout align-center class="fill-height px-10">-->
<!--            <h3 style="color: #893FF2; cursor: pointer">URBAN GREEN DATA MANAGEMENT</h3>-->
<!--          </v-layout>-->
<!--        </div>-->
        <div
            class="d-flex flex pa-3"
            style="background-color: #f9f4fe"
        >
          <div
              class="d-flex align-center fill-height"
              style="background-size: 100% 100%; background-color: #F9F4FE; width: 100%"
          >
            <div class="d-flex align-center fill-height flex-wrap" style="width: 100%">
              <div style="flex: 3; border-radius: 10px; background-position-y: bottom; background-size: contain;" class="fill-height flex-column left-info align-center"
                   :style="{'background-image': `url(${require('@/assets/images/bg-login.png')}`}"
              >
                <div style="height: 100px; border-radius: 10px;" class="flex-none">
                  <div
                      class="d-flex align-center fill-height pt-5"
                      style="border-radius: 10px"
                  >
                    <img alt="logo" :src="require('@/assets/images/logo.png')" width="180px">
                  </div>
                </div>
                <div class="d-flex fill-height justify-center" style="padding-top: 20px; border-radius: 10px; width: 100%">
                  <div class="d-flex flex-column align-center" style="width: 100%">
                    <div class="pb-12 flex-none">
                      <span style="color: #333333; font-size: 29px; font-weight: 400; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">www.skymapdataservice.com</span>
                    </div>
                    <span style="text-transform: uppercase; font-weight: bold; color: #893FF2; font-size: 42px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">subscription of data services</span>
                    <div class="pt-3 flex-none pb-2" style="width: 100%">
                      <v-layout justify-center align-center>
                        <div class="d-flex justify-center" style="width: 200px; text-transform: uppercase; font-weight: 400; color: #893FF2; font-size: 33px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">monitor</div>
                        <div class="d-flex justify-center" style="width: 200px; text-transform: uppercase; font-weight: 400; color: #893FF2; font-size: 33px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">detect</div>
                        <div class="d-flex justify-center" style="width: 200px; text-transform: uppercase; font-weight: 400; color: #893FF2; font-size: 33px; line-height: 70px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important">identify</div>
                      </v-layout>
                    </div>
                    <div class="pt-7 flex d-flex" style="width: 450px">
                      <v-layout align-center column>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Dashboard and Map View
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Visualise and Utilise
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Compare and Qualify
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Inform and Act
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Location and Duration
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Hot Spot and Trend Spotting
                      </div>
                        <div class="flex" style="color: #000000; font-size: 2.65vh; font-weight: 500;">
                        Analyse and Predict
                      </div>
                      </v-layout>
                    </div>
                    <div class="flex-none" style="text-align: center; height: 60px">
                      <v-layout align-end class="fill-height">
                        <span style="color: #000000; font-size: 19px; font-weight: 400;">Presented by SkymapGlobal.com | powered by EOFactory.ai</span>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 2; height: 100%">
                <div class="d-flex align-start justify-end fill-height">
                  <div style="width: 100%; height: 100%; background-color: #fff; border-radius: 10px" class="elevation-2">
                    <div class="d-flex flex-column fill-height pt-9">
                      <div style="flex: none; width: 100%; height: 60px; background-color: #fff; padding-left: 50px; padding-right: 50px">
                        <div class="d-flex fill-height">
                          <div style="font-size: 35px; color: #893FF2; text-transform: uppercase; transform: scale(1, 0.9)"><span style="font-weight: 700; font-variant: small-caps;">Log </span>In</div>
                        </div>
                      </div>
                      <div class="d-flex flex-column flex pt-4">
                        <v-form @submit="login" onSubmit="return false" class="d-flex flex-column flex-none" v-model="valid" ref="formLogin">
                          <div style="flex: none; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;">
                            <span style="font-size: 22px; font-weight: bold;">Email</span>
                            <div style="width: 100%;" class="pt-3">
                              <v-text-field
                                  class="input-login"
                                  height="50"
                                  background-color="white"
                                  dense
                                  flat
                                  solo
                                  v-model="email"
                                  label="Email"
                                  placeholder="Email"
                                  style="color: black !important;"
                                  :rules="[rules.requiredUserName]"
                              >
                              </v-text-field>
                            </div>
                            <span style="font-size: 22px; font-weight: bold;;">Password</span>
                            <div style="width: 100%;" class="pt-3">
                              <v-text-field
                                  class="input-login"
                                  height="50"
                                  flat
                                  background-color="white"
                                  solo
                                  :type="isShowPassword ? 'text' : 'password'"
                                  v-model="password"
                                  label="Password"
                                  placeholder="Password"
                                  :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="isShowPassword = !isShowPassword"
                                  :rules="[rules.requiredPassword]"
                              >
                              </v-text-field>
                            </div>
                            <div style="width: 100%; height: 20px;">
                              <div class="fill-height d-flex justify-end">
                                <ForgotPassword/>
                              </div>
                            </div>
                            <div style="width: 100%; text-align: center;" class="pt-5">
                              <v-btn color="#e7d9fc" width="100%" height="50" type="submit" style="border-radius: 10.5px; text-transform: capitalize; font-weight: bold">Log in</v-btn>
                            </div>
                          </div>
                        </v-form>
                        <div class="flex d-flex justify-center align-center" :style="{'background-image': `url(${require('@/assets/images/background-login-form.png')}`}"
                             style="background-size: auto 100%; background-position-y: bottom; background-position-x: center">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  authLogin,
  checkToken
} from "@/backend";
import Message from "@/components/Message";
import AvatarPopOver from "@/components/AvatarPopOver";
import {mapState} from "@/store/ults";
import api from "@/api";
import ForgotPassword from "@/views/forgotPassword/ForgotDialog";
export default {
  components: {
    Message,
    ForgotPassword,
    AvatarPopOver
  },
  data: () => ({
    loading: false,
    valid: false,
    popupForgot: false,
    rules: {
      requiredUserName: value => (!!value && !!value.trim()) || 'Email is required',
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
    },
    email: undefined,
    password: undefined,
    isShowPassword: false
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created () {
    const token = api.getToken()
    if (token) this.checkTokenExist()
  },
  methods: {
    async login () {
      this.$refs.formLogin.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await authLogin({email: this.email, password: this.password})
        location.href = `${location.origin}/app`
      } catch (e) {
        // console.log(2, e)
        // alert('Data invalid')
      } finally {
        this.loading = false
      }
    },
    async checkTokenExist () {
      try {
        const res = await checkToken()
        location.href = `${location.origin}/app`
      } catch (e) {
        console.log(e.message)
        api.deleteToken()
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    }
  }
};
</script>
<style scoped>
/deep/
.input-login .v-input__slot {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  color: black !important;
}
/deep/
.input-login v-input {
  color: black !important;
}
@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}
.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}
.header {
  height: 800px;
}
.content {
  height: 100vh
}
.join-button {
  display: flex;
  min-width: 0;
  min-height: 0;
}
.left-info {
  display: flex;
  min-width: 0;
  min-height: 0;
}
@media only screen and (max-width: 925px) {
  .left-info {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}
@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}
.main-layout .custom-list-item {
  padding-left: 30px;
}
.flex {
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}
</style>
<style>
html,
body,
.app {
}
</style>
