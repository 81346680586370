<template>
  <v-dialog
      v-model="forgotPasswordDialog"
      max-width="500"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <a v-bind="attrs" v-on="on" @click="popupForgot = true" :style="{'text-decoration-line': hover ? 'underline' : 'none'}" style="font-size: 16px; color: #777c85; font-style: italic">Forgot Your Password?</a>
      </v-hover>
    </template>

    <v-card>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">FORGOT PASSWORD</span>
        <v-spacer/>
        <v-btn icon small @click="forgotPasswordDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="forgotPassword" onSubmit="return false;" v-model="valid" ref="formData">
          <v-text-field outlined dense v-model="email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn width="100" color="#893FF2" @click="forgotPassword" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  forgotPassword
} from "@/backend";
export default {
  name: "PopupCreateUser",
  data () {
    return {
      loading: false,
      forgotPasswordDialog: false,
      valid: false,
      email: undefined,
      rules: {
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
      }
    }
  },
  mounted() {
  },
  watch: {
    forgotPasswordDialog (val) {
      if (val) {
        this.email = undefined
        if (this.$refs.formData) this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    async forgotPassword () {
      this.$refs.formData.validate()
      if (this.valid) {
        this.loading = true
        try {
          const res = await forgotPassword({
            "email": this.email,
          })
          alert(res.data.message)
          this.forgotPasswordDialog = false
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
