<template>
  <v-app id="inspire" class="main-layout">
    <Message/>
    <ChangePassword ref="changePassword"/>
    <v-app-bar style="box-shadow: none !important;" class="main-header" app clipped-left color="#f4effa" dark>
      <img class="ml-5" alt="logo" :src="require('@/assets/images/National_Parks_Board_Logo-removebg-preview.png')" width="50" height="66">
      <div class="px-8">
        <h3 @click="goToLink('/app')" style="color: #893FF2; cursor: pointer">DASHBOARD NPARKS</h3>
      </div>
      <v-spacer/>
      <PopupDownload
         v-if="currentUser.role != 'SUBMEMBER'"
      />
      <div style="padding-left: 30px">
        <v-layout column align-center justify-center>
          <span style="color: #000000; font-size: 15px">{{ currentUser.name }}</span>
          <span style="color: #000000; font-size: 10px; text-transform: capitalize !important;">{{ currentUser.role.toLowerCase() }} account</span>
        </v-layout>
      </div>
      <div class="space-around" style="margin-left: 20px">
        <AvatarPopOver></AvatarPopOver>
      </div>
      <v-menu
          absolute
          offset-y
          style="max-width: 600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon small color="#893FF2"><v-icon>mdi-menu-down</v-icon></v-btn>
        </template>

        <v-list dense>
          <v-list-item v-if="currentUser.role === 'ADMIN'" @click="goToLink('/app/userManagement')" color="#893FF2">
            <v-list-item-title>User management</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentUser.role === 'ADMIN'" @click="goToLink('/app/activityLog')" color="#893FF2">
            <v-list-item-title>Login history</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToLink('/app/profile')" color="#893FF2">
            <v-list-item-title>User Profile</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <img class="ml-5" alt="logo" :src="require('@/assets/images/logo.png')" width="75" height="45">
    </v-app-bar>
    <v-main class="background">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "@/store/ults";
import AvatarPopOver from "@/components/AvatarPopOver";
import Language from "@/components/Language";
import { toggleDark } from "@/plugins/vuetify";
import Message from "@/components/Message";
import PopupDownload from "@/components/download/PopupDownload";
import ChangePassword from "@/components/user/ChangePassword";

export default {
  components: {
    ChangePassword,
    PopupDownload,
    Message,
    AvatarPopOver,
    Language,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    miniSize: true,
    keyWord: undefined
  }),
  computed: {
    ...mapState("main", ["menu"]),
    ...mapState("auth", ["currentUser"]),
  },
  created() {},
  mounted() {
    if (!this.currentUser.verified) this.$refs.changePassword.dialog = true
  },
  methods: {
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    },
    toggleDark,
  },
};
</script>

<style>
  .custom-nav .v-navigation-drawer__content {
    overflow-y: hidden;
  }

  .main-layout .v-list-group__header .v-list-item {
    padding-left: 0;
  }

  .main-layout .custom-list-item {
    padding-left: 30px;
  }
  .main-header .v-toolbar__content {
    border-bottom: 1px solid lightgray;
  }
  .search-input .v-input__slot {
    border-color: red !important;
    min-height: 0 !important;
  }
  .v-select__selection {
    font-size: 12px !important;
  }
</style>
<style>
  html,
  body,
  .inspire {
    overflow-y: hidden;
    font-family: "Roboto", sans-serif;
  }
</style>
